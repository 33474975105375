.button a, input[type=submit] {
	color: #fff;
	text-decoration: none;
	padding: 10px 30px;
	background: $brand-color;
	border-radius: 3px;
	border: 1px solid rgba(255,255,255,.5);
	transition: .2s ease-in-out;
}

.button a:hover, input[type=submit]:hover {
	border: 1px solid #fff;
	background: $secondary-brand-color;
	cursor: pointer;
}

.button.alt a {
	background: rgba(255,255,255,0.15);
	border-radius: 3px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	padding: 16px 50px;
}

.button.alt a:hover {
	background: #fff;
	color: $brand-color;
}

textarea, input, button, select { font-family: inherit; font-size: inherit; }

input[type=submit] {
	margin: 20px 0 0 0;
}

label, input, textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
}

textarea {
	resize: vertical;
	height: 150px;
}

label {
	margin: 20px 0 5px 0;
}

input, textarea {
	padding: 10px;
	font-size: 1em;
}

input, textarea {
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	outline: none;
	border: 1px solid #DDDDDD;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
	border: 1px solid rgba(81, 203, 238, 1);
}

#gcal-button, #outlook-button {
	display: inline-block;
	padding: 0px;
	border-radius: 0px;
	border: 1px solid transparent;
}

#gcal-button {
	background-color: #4285F4;
	font-family: roboto;
	font-size: 15px;
}

#outlook-button {
	background-color: #2F2F2F;
	color: #ffffff;
	font-family: Segoe UI Regular;
	font-size: 15px;
}
.google-logo{
	padding: 11px;
	display: inline-block;
	background-color: white;
}

.microsoft-logo{
    padding-left: 11px;
    padding-top: 12px;
	display: inline-block;
	svg{
		height: 18px;
		width: 18px;
	}
}

.sign-up-text{
    vertical-align: top;
    line-height: 40px;
    padding-left: 16px;
    padding-right: 24px;
}

.shadow {
	-webkit-box-shadow: 3px 1px 5px 1px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
	-moz-box-shadow:    3px 1px 5px 1px #ccc;  /* Firefox 3.5 - 3.6 */
	box-shadow:         3px 1px 5px 1px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }