$brand-color: #005dff;
$secondary-brand-color: #4182e4;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

$color-remote: #FF33CC;
$color-meeting: #FF9900;
$color-goal: #26BF00;

// Breakpoints
$tablet: "(min-width: 450px)";
$tablet-only: "(min-width: 450px) and (max-width: 620px)";
$mobile-and-tablet: "(max-width: 620px)";
$mobile-and-tablet-new: "(min-width: 320px) and (max-width: 768px)";
$mid-point: "(min-width: 620px)";
$mid-point-only: "(min-width: 620px) and (max-width: 768px)";
$desktop: "(min-width: 768px)";
$desktop-only: "(min-width: 768px)";
$mobile: "(max-width: 450px)";
$mobile-only: "(max-width: 450px)";
