.plans {
	@extend %flexbox;
	@include flex-flow(wrap);
}
// .pricing-button {
// 	width: 100%;
//     max-width: 420px;
//     margin: 0 auto;
// 	.pricing-inner {
// 		position: relative;
// 		display: flex;
// 		background: #F3F3F3;
// 		width: 100%;
// 		border-radius: 999px;
// 		padding: 4px;
// 		height: 44px;
// 		margin: 0 0 40px;
// 		&::before{
// 			position: absolute;
// 			content: "";
// 			display: block;
// 			width: 50%;
// 			top: 4px;
// 			bottom: 4px;
// 			right: 4px;
// 			background: #fff;
// 			box-shadow: 0px 1px 3px rgba(0,0,0, 0.08);
// 			border-radius: 999px;
// 			transition: all 0.15s ease-in-out;
// 		}
// 		button {
// 			position: relative;
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			font-weight: 500;
// 			line-height: 1em;
// 			letter-spacing: 0.2px;
// 			text-align: center;
// 			white-space: nowrap;
// 			text-decoration: none;
// 			color: #1d1d1d;
// 			min-width: 80px;
// 			padding: 0 1.8rem;
// 			cursor: pointer;
// 			transition: all .25s ease;
// 			box-shadow: none;
// 			user-select: none;
// 			outline: none;
// 			border: 0;
// 			height: 100% !important;
// 			width: 50%;
// 			border-radius: 999px;
// 			background: transparent;
// 			font-size: 1.5rem;
// 		}
// 	}
// 	.left::before {
// 		transform: translateX(calc(-100% + 8px));

// 	}
	
	
// }
.pricing-button {
	width: 100%;
	max-width: 420px;
	margin: 5rem  auto 0 auto;
	.pricing-inner {
		position: relative;
		display: flex;
		background: #e0e0e0;
		width: 100%;
		border-radius: 999px;
		padding: 4px;
		height: 48px;
		margin: 0 0 40px;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 50%;
			top: 4px;
			bottom: 4px;
			right: 4px;
			background: linear-gradient(135deg, #005dff, #78a5e9);
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
			border-radius: 999px;
			transition: all 0.3s ease-in-out;
		}
		button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			line-height: 1em;
			letter-spacing: 0.5px;
			text-align: center;
			white-space: nowrap;
			text-decoration: none;
			color: #333;
			min-width: 80px;
			padding: 0 1.8rem;
			cursor: pointer;
			transition: all 0.3s ease;
			user-select: none;
			outline: none;
			border: 0;
			height: 100% !important;
			width: 50%;
			border-radius: 999px;
			background: transparent;
			font-size: 1.4rem;
			&.active {
				color: white; /* Active text color */
			}
		}
	}
	.left::before {
		transform: translateX(calc(-100% + 8px));
	}
}

.getstarted {
	width: 100%;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 40px;
}
.plan {
	flex: 1;
	text-align: center;
	 min-height: 80px;
}

.plan--align-right {
	// text-align: right;
	margin-right: 2rem;
	display: none;
}

.plan__title {
	margin-top: 0;
}

.plan__savings {
	color: #25c782;
	font-weight: bold;
	font-size: 1.5rem;
}

.plan__pricing {
	font-size: 2rem;
	margin-bottom: 1rem;
}
.plan__name{
	font-size: 1rem;
}
.plan__type{
	text-align: left;
	font-size: 0.9rem;
	display:inline-block;
}
.yearly__actual__plan{
	display: block;
	font-size: 1.5rem;
}

.plan__amount--slashed {
	background-color: #FFE200;
	text-decoration: line-through;
}

.plan__button {
	padding: .75rem 1rem;
	border-radius: 5px;
	color: #fff;
	background-color: #005dff;
	text-decoration: none;

	&:hover {
		background-color: #25c782;
	}
}
.wrapper-list {
	display: flex;
	justify-content: space-between;
	gap: 20px;
}


.faq {
	font-size: 1.38rem;

	@media #{$desktop} {
		@include columns(2);
	}
	color: #666;
	div {
		break-inside: avoid;
		padding: 25px 0;
	}

	dt {
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	dd {
		padding: 0;
		margin: 0;

	}
}

.ul--3col {
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}
.pricing-card ul {
	list-style: none;
	padding: 0;

}
.pricing-card ul li {
	position: relative;
	color: rgba(0, 0, 0, 0.527);
	font-weight: 500;
	display: flex;
	align-items: center;
}

.pricing-card ul li img {
	width: 14px;
}

.pricing-card ul li::before {
	position: absolute;
	content: "";
}

.text-container img, .text-container iframe, .text-container video {
    width: 100%;
    display: block;
    margin: 1rem !important;
    max-width: 560px;
}


// .wrapper-list {
//     display: flex;
//     justify-content: space-between;
//     margin: 20px 0;
//     gap: 20px; /* Adjusts space between the columns */
// }

// .wrapper-list ul {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;
//     background-color: #f9f9f9; /* Background color for the tiles */
//     padding: 15px;
//     border-radius: 8px; /* Rounded corners for the tiles */
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
//     flex: 1;
// }

// .wrapper-list ul li {
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px;
// }

// .wrapper-list ul li img {
//     margin-right: 10px;
//     width: 20px; /* Adjust the size of the checkmark */
//     height: auto;
// }

.wrapper-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
    gap: 20px; /* Reduce this value to decrease the space between columns */
    max-width: 1000px; /* Optionally set a maximum width for the container */
    margin: 0 auto; /* Center the container */
}

.wrapper-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.wrapper-list h4 {
    margin-bottom: 10px; /* Space between the heading and list */
	color: rgba(0, 0, 0, 0.527) !important;
}
.wrapper-list h3{
	margin-top:0 !important;
	
}

.wrapper .plan {
	height: auto !important;
	min-height: auto !important;
}
.free-plan{
	display: flex;
	align-items: flex-end;
}
.amount{
	color:#000;
}
