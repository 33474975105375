.tweet-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.tweet-cell{
    padding: 20px;
    text-align: left;
}

.tweet-bubble{
    position: relative;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.1), 0 0 2px rgba(0,0,0,.1);
    -moz-box-shadow: 0 2px 8px rgba(0,0,0,.1),0 0 2px rgba(0,0,0,.1);
    -o-box-shadow: 0 2px 8px rgba(0,0,0,.1),0 0 2px rgba(0,0,0,.1);
    -ms-box-shadow: 0 2px 8px rgba(0,0,0,.1),0 0 2px rgba(0,0,0,.1);
    box-shadow: 0 2px 8px rgba(0,0,0,.1), 0 0 2px rgba(0,0,0,.1);
}

.tweet-bubble::after{
    content: '';
    display: block;
    width: 64px;
    height: 28px;
    position: absolute;
    left: 28px;
    bottom: -28px;
    z-index: 1001;
    background-image: url(../assets/images/twitter-files/bubble-tip.png);
    background-repeat: no-repeat;
    -webkit-background-size: 64px 28px;
    -moz-background-size: 64px 28px;
    background-size: 64px 28px;
}

.tweet-content {
    padding: 11px 15px 15px 15px;
    overflow: hidden;
    color: #303336;
}

.tweet-author-meta {
    margin-top: 20px;
    font-size: 13px;
    display: flex;
    flex-wrap: nowrap;
}

.tweet-avatar{
    width: 40px;
    img{
        width: 100%;
        display: block;
        margin: 0; 
        max-width: none;
        height: auto;
        border-radius: 3px;
    }
} 

.tweet-author-bio {
    margin-left: 10px;
}

.tweet-author-name {
    font-style: italic;
}

.twitter-handle {
    color: #FFE200;
}