.nav .active {
    font-weight: bold;
    text-decoration: underline;
}

.nav-siblings {
    display: flex;
}

.nav-sibling {
    flex-grow: 1;
}

.nav-siblings .nav-sibling a {
    box-shadow: none;
    
    &:hover {
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }
}

.nav-siblings .is-previous a::before {
    content: '<';
    margin-right: .5em;
    color: #aaa;
}

.nav-siblings .is-next {
    text-align: right;
}

.nav-siblings .is-next a::after {
    content: '>';
    margin-left: .5em;
    color: #aaa;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .nav {
        display: none !important;
    }
}
