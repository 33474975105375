.features-row .container.flex{
    flex-direction: row;
    .text.editable p, .text.editable h2 {
        text-align: left;
    }
}

.hero .container.flex{
    flex-direction: row-reverse;
}

section:nth-child(even).features-row .container.flex {
    flex-direction: row-reverse;
}

.zoom {
    transition: transform .2s; /* Animation */
}
.zoom:hover {
    transform: scale(1.1); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.features-page{

    .features-row{
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .testimonial{
        background-color: #f5f5f5;
    }
    
    .hero{
      padding-top: 110px;
      background-color: #4182e4;
      h1, p {
        color: white;
        }  
    }

    section.testimonial::before{
        background-color:  #f5f5f5;
       
    }
 
    section.testimonial:nth-child(odd)::before{
        background-color:  black;
       
    }

    section.bottom-cta::before {
        background-color: #4182e4; 
    }

    .section-blue::before {
        background-color: #4182e4;
    }

    .section-blue {
        color: white;
    }

    section.bottom-cta{
        h1, p {
            color: white;
            }  
        .author{
            font-weight: bold; 
        }
    }

    section.testimonial {
        h1, p {
            color: #666;
            } 
    }
}

.preheader-text {
    font-family: "Fjalla One";
    text-align: center;
    color:white;
}

.section-dark .shadow {
    box-shadow: none;
}