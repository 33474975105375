.box-item {
    box-sizing: border-box;
    padding: 1rem;
    vertical-align: top;
    text-align: center;

    p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.6rem;
    }
}

