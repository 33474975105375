.container, .text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
	@media #{$desktop} {
        padding: 0 20px;
    }
	@media #{$tablet} {
        padding: 0 34px;
    }
}

.flex-center {
	display: flex;
    align-items: center;
    justify-content: center;
}

.width-80 {
	width: 80%;
}

.text-container {
	max-width: 750px;
}

.italic {
	Font-style: italic;
}

.container {
	max-width: 1140px;

	&.max-container {
		max-width: 100%;
		padding: 0 30px;
	}
}

header {
	color: #fff;
	padding: 20px 0;
	background: white!important; /* Old browsers */

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 1.7em;
		line-height: 0;

		a {
			display: inline-block;
		}

		img {
			display: block;
			width: auto;
		}
	}
}

.content {
	background: #fff;
	padding: 1px 0 0 0;
	position: relative;
}

.screenshot{
	max-width: 100%;
	height: auto;
	display: block;
	box-shadow: 0 1px 0 #ccc, 0 1px 0 1px #eee;
	border-radius: 2px;
	margin-left: auto;
	margin-right: auto;
	background: #DDD url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2212%22%20viewBox%3D%220%200%2044%2012%22%3E%3Ccircle%20cx%3D%226%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2222%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2238%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3C%2Fsvg%3E') 4px 4px no-repeat;
	padding: 20px 0 0 0;
	position: relative;
}

section {
	padding: 100px 0;
}

section + section {
	padding-top: 0;
}

.subtext {
	margin-top: 10px;
	text-align: center;
}


.cta {
	margin: 60px 0;
}

.page h2 {
	text-align: left !important ;
}

blockquote {
	padding: 18px 25px;
	margin: 0;
	quotes: "\201C""\201D""\2018""\2019";
	font-style: italic;

	.author {
		display: block;
		font-weight: bold;
		margin: 10px 0 0 0;
		font-size: .85em;
		font-style: normal;
	}

	p {
		display: inline;
	}
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.square-image {
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 25px auto 0 auto;
	position: relative;
	border-radius: 200px;

	img {
		position: absolute;
		left: -1000%;
		right: -1000%;
		top: -1000%;
		bottom: -1000%;
		margin: auto;
		width: 187px;
	}
}

.page {
	margin-bottom: 0;
	padding-bottom: 80px;
}

.center-text {
	text-align: center;
}

.editor-link {
 	display: none;
	margin-top: 0;
	.btn {
		border: 0;
		border-radius: 2px;
		width: 100%;
		max-width: 500px;
		box-sizing: border-box;
		font-size: 2rem;
		text-decoration: none;
		padding: 10px 15px;
		margin: 0;
		font-size: 18px;
		cursor: pointer;
		background-color: #f7e064;
		color: #333;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #f4d525;
			color: #333;
		}
	}

}

.button-grid {
  display: flex;
  color: #444;
  flex-wrap: wrap;
  justify-content: center;

  a, button {
	margin: 0 .25rem;
  }
}


.hero .container.column-flex {
	flex-direction: column;
}

.column-flex {
	display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
	flex-direction: column;  
}
.row-flex {
	display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
	align-items: left;	
	&.column-flex{
		align-items: baseline;	
	}
	@media #{$desktop} {
		flex-direction: row;
    }
	@media #{$mobile-and-tablet} {
		flex-direction: column;
	}	
}

.text-container iframe.weekplan-tv {
	margin: 4.5rem auto;
}

.for-mobile-only {
	display: none;
	@media #{$mobile-only} {
		display: block;
	}
}


.for-mobile-and-tablet {
	display: none;
	@media #{$mobile-and-tablet} {
		display: block;
	}
}
.for-mobile-and-tablet-new {
	display: none;
	@media #{$mobile-and-tablet-new} {
		display: block;
	}
}

.for-mid-point-only {
	display: none;
	@media #{$mid-point-only} {
		display: block;
	}
}

.for-desktop-only {
	display:none;
	@media #{$desktop-only} {
		display: block;
	}
}

.no-minimum-height{
	min-height: 0;
}
.text-left {
    text-align: left !important;
}
.align-left{
	align-items: left !important;
}
.sticky {
	position: fixed !important;
	top: 0;
	width: 100%;
	z-index: 999;
}
.features-page{
	padding-top:129px;
}
.pt-129{
	padding-top:129px;
}
#subcription-modal{
	z-index: 1001 !important;
}