.button .huge {
    font-size: 2rem;
    display: block;
    text-align: center;

    @media #{$desktop} {
        display: inline-block;
    }
}

.button a {
    font-family: $font-brand;
    text-transform: uppercase;
    border: none;

    &:hover {
        background-color: $color-success;
    }
}

a.button, .button a, input[type=submit] {
    color: #fff;
    text-decoration: none;
    padding: .5em;
    background: $color-brand;
    border-radius: 3px;
    border-width: 0;
    transition: none;

    &:hover {
        border-width: 0;
        background-color: $color-success;
    }
}

.button .svg-inline--fa {
    font-size: 1rem;
    margin-right: .5rem;
    color: #fff;
}