.post-card-list {
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3rem;
    flex-direction: row;
    flex-wrap: wrap;

    h2 {
        width: 100%;
    }
}

.post-card {
    margin: 0 .5rem 1rem .5rem;
    min-width: 15rem;   
    min-height: 20rem;
    background: #fff 50%;
    background-size: cover;
    border-radius: 5px;
    box-shadow: 8px 14px 38px rgba(39,44,49,.2), 1px 3px 8px rgba(39,44,49,.03);
    transition: all .5s ease;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 0;
    -webkit-filter: grayscale(20%);
    filter: grayscale(20%);

    h3, h2 {
        margin: 0 0 .5rem 0 !important;
        font-size: 1.6rem !important;
    }

    header, section {
        padding: 0;
    }

    .post-card-image {
        width: auto;
        height: 200px;
        background: #c5d2d9 no-repeat 50%;
        background-size: cover;
    }

    
    a:hover {
        text-decoration: none;
    }

    .post-card-content {
        padding: 1rem;
    }

    p {
        font-size: 1rem;
    }
}
article.post-card{
    max-width: 17rem;
}
.post-card:hover {
    box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.30), 1px 13px 16px rgba(39, 44, 50, 0.03);
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}