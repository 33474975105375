.blog-posts {
	list-style: none;
	padding: 0;

	li {
		margin: 100px 0;
	}
}

body .blog-post {
	h1, h2, h3, h4{
		text-align: left !important;
	}
	h4, h5{
		width:100% !important;
		margin-block-end: 0;
	}
	h5{
		font-size: 1.2rem;
		margin-block-end: 0;
	}
	p{
		margin-block-start: 0.5em;
	}


}

.blog-post {
	.author {
		padding: 30px 0 0 0;
		border: 1px solid #eee;
		margin: 30px 0;
		font-size: .8em;

		.square-image {
			width: 125px;
			height: 125px;
			margin-top: 0;
		}
		.blurb {
			text-align: center;
		}
	}

	h3 {
		margin: 0;
		a {
			color: #000;
			text-decoration: none;
			font-weight: normal;
			font-size: 1.3em;
		}
	}

	h2 {
		text-align: left;
	}

	table {
		border-collapse: collapse;
		th, td {
			padding: 8px 16px;
			border: 1px solid #ccc;
		}
	}

	.blog-navigation {
		font-size: 14px;
		display: block;
		width: auto;
		overflow: hidden;
		a {
			display: block;
			width: 50%;
			float: left;
			margin: 1em 0;
		}

		.next {
			text-align: right;
		}
	}

	.post-details {
		border-bottom: 1px solid #eee;
		font-size: .9em;

		.blog-filter {
			display: inline-block;
			text-align: left;

			a {
				position: relative;
				top: -5px;
			}
		}

		a {
			text-decoration: none;
		}

		.post-date {
			float: right;
		}

		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.post-content {
		.button {
			margin: 30px 0 0 0;
		}
	}
}

.pagination {
	text-align: center;
}

.blog-filter {
	text-align: center;
	a {
		background: #eee;
    padding: 3px 5px;
    font-size: .8em;
    border-radius: 5px;
    color: #888;
		transition: .2s ease-in-out;

		&:hover {
			color: #555;
			text-decoration: none;
		}
	}
}

.blog-filter.cross a {
	padding-right: 8px;

	&:after {
		content: "x";
		font-size: .5em;
		position: relative;
		bottom: 4px;
		right: -3px;
	}
}

a img.banner-cta{
	max-width: 100%;
	margin-top: 20px;
}

.icon-bar {
	position: fixed;
	bottom: 10%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
  }
  
  .icon-bar a {
	display: block;
	text-align: center;
	padding: 16px;
	transition: all 0.3s ease;
	color: white;
	font-size: 20px;
  }
  
  .icon-bar a:hover {
	background-color: #000;
  }
  
  .facebook {
	background: #3B5998;
	color: white;
  }
  
  .twitter {
	background: #55ACEE;
	color: white;
  }
  
  .google {
	background: #dd4b39;
	color: white;
  }
  
  .linkedin {
	background: #007bb5;
	color: white;
  }
  
  .youtube {
	background: #bb0000;
	color: white;
  }

  .video-float {
	position: fixed;
	bottom: 5%;
	right:0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 99;
	height: 200px;
	width: 355px!important;
  }
  .text-container .video-float iframe:hover {
	  opacity: 1;
  }

  .text-container .video-float iframe{
	opacity: 0.8;
	margin: 0;
	float: right;
    width: 355px;
  }

  .close-button {
	position: absolute;
	font-size: 30px;
    top: -32px;
	cursor: pointer;
  }

#bottom-cta-bar {
	.cta-content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;
		margin: 0 auto;
	}
	z-index: 1;
		position: fixed;
		bottom: 0px;
		left: 0px;
		height: 70px;
		width: 100%;
		background-color: black;
		.cta-text{
			width: 100%;
			align-self: auto;
			background-color: black;
			width: 100%;
			p {
				text-align: center;
			}
		}
		.cta-button{
			width: 70%;
			align-self: auto;
			background-color: black;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	@media #{$mobile} {
		// display: none;
	}
	@media #{$desktop} {
		.cta-content{
			width: 60%;
		}
		.cta-button{
			width: 30%;
		}
	}
}

.underlined {
	border-bottom: solid 1px #80808063;
    padding-bottom: 9px;
}