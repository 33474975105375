

.active {
	opacity: 1;
	transition: visibility 0s, opacity 0.5s linear!important;
}

.hidden {
	position:absolute;
	left: -1000px;
	visibility: hidden!important;
	opacity: 0;
  	transition: visibility 0s, opacity 0.5s linear!important;
  }

  ul.landing-page-list {
	list-style: none;
	text-align: center; 
	margin-left: 0px;
	padding-left: 0px;
	li {
		display: inline;
	}
  }