$dark: #18202B;
$grey: #5D626A;
$gray: #FAFAFB;
$color-success: #25c782;
$color-brand: #005dff;
$font-default: 'Source Sans Pro';
$font-brand: 'Fjalla One';

@import "custom/post-card.scss";
@import "custom/feature.scss";
@import "custom/box-item.scss";
@import "custom/step-item.scss";
@import "custom/icon.scss";
@import "custom/button.scss";
@import "custom/nav.scss";


body {
    font-family: $font-default;
    font-size: 16px;
    color: #666;
    line-height: 1.4;
}

.blog-navigation a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;

    &.next {
        text-align: right;
    }
}

.post-content>div,
.blog-post .blog-navigation {
    width: 100%;
}

.next-values+ul li {
    padding: 0 .5em;
    display: inline-block;
    border: 1px solid #ccc;
}

.banner {
    text-align: center;
    border: 1px solid $color-brand;
    border-left-width: 0;
    border-right-width: 0;

    padding: 1em;
    margin-bottom: 3rem;
    margin-top: 3rem;

    .button {
        margin-left: .5rem;
    }
}

p+blockquote {
    margin-bottom: 20px;
    color: $dark;
}

.text-container {

    img,
    iframe,
    video {
        width: 100%;
        display: block;
        margin: 1.5rem auto;
        max-width: 560px;
    }
}

h1,
h2,
h3,
h4 {
    color: $dark;
}
.blog-post h4{
    font-size: 1.5rem !important;
}

.page .text-container {
    h2 {
        margin-top: 5rem;
    }

    h3 {
        margin-top: 4rem;
    }
}

ol,
ul {
    margin-left: 1rem;
}

li {
    font-size: 1.38rem;
    margin-bottom: .5rem;
    line-height: 1.4;
}

h1+.subtext {
    margin-bottom: 3rem;
}

h2+p>img {
    margin-top: 1.5em;
}

h1+p {
    margin-top: 5rem;
}

footer {
    ul {
        margin-left: 0;
        padding-left: 0;
    }

    h4 {
        color: #fff;
    }
}

.footer-links {
    text-align: left;

    @media #{$tablet} {
        display: initial;
    }
}

.footer-links li {
    max-width: initial;
    font-size: 1rem;
}

.call-to-action {
    margin-bottom: 3rem;

    .button {
        display: block;

        @media #{$tablet} {
            display: inline-block;
        }
    }
}

.call-to-action-alternative {
    margin-top: .5rem;
    color: #999;
    text-align: center;
}

.call-to-action a {
    margin-bottom: 1rem;
}

.no-mobile {
    display: none !important;

    @media #{$tablet} {
        display: block;
    }
}

.awards {
    display: flex;
    justify-content: center;
    margin-top: 3rem;


    img {
        width: 275px;
        margin: 0 .5rem;
    }
}

.socialproof-number {
    display: inline-block;
    font-size: 3em;
    color: #000;
    vertical-align: middle;
}

.socialproof {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
        filter: grayscale(1);
        height: 60px;
        margin-left: 4em;
        opacity: 0.5;
        width: inherit;
    }
}


.content>section {
    position: relative;
    padding: 5rem 0 10rem 0;
}

.content>section:before,
footer:before {
    background: #ffffff;
    content: '';
    position: absolute;
    left: 0px;
    top: -40px;
    height: calc(80px + 100%);
    width: 100%;
    transform: skewY(-2deg);
    z-index: 0;
}

.content>section:first-child:before {
    display: none;
}

.section-dark {
    color: #fff;

    p {
        color: #aaa;
    }

    h2,
    h3 {
        color: #fff;
    }

}

.section-dark__h2 {
    margin-bottom: 2rem;
    text-align: center;
}



footer:after {
    content: '';
    position: absolute;
    background: $dark;
    left: 0px;
    bottom: -65px;
    height: 65px;
    width: 100%;
    z-index: 0;
}

.content>.section-dark:before,
footer:before {
    background: $dark;
}

.content>.section-gray:before {
    background: $gray;
}

.content section .flex,
.content section:nth-child(even) .flex {
    flex-direction: column;
}

.content section .flex .text {
    margin-bottom: 3rem;

    h2,
    p {
        text-align: center;
    }
}

header,
.hero {
    background: none;
    background-color: white;
    color: $dark;
}

header {
    padding: 3rem 0;
}

h1 {
    font-weight: 900;
    font-size: 3.7rem;
    line-height: 1;
    letter-spacing: -1.7px;
}

h2 {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.1;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
}

h3 {
    font-size: 2.1rem;
    line-height: 1.1;
}

.page h1 {
    text-align: center;
}

.page h2 {
    text-align: left;
    margin-top: 3rem;
}

nav {
    font-size: 0.9rem;
}


nav a {
    color: $grey;
    transition: none;
    font-family: $font-brand;
    letter-spacing: 1px;

    &:hover,
    &.active {
        border-color: $brand-color;
        color: $brand-color;
    }
}

.hero {

    p {
        color: #666;
    }
}


.post-content {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
}

.blog-posts li.blog-post h3 {
    margin-bottom: .5rem;
    margin-top: 0;
}

.blog-post h3 a {
    text-decoration: underline;
    text-decoration-color: #ccc;

    &:hover {
        text-decoration-color: $color-brand;
    }
}

.blog-post h3 a:hover {
    color: $brand-color;
}


.blog-post .post-details {
    border-bottom-width: 0;
}

.blog-posts {
    margin-top: 3rem;
}

.blog-posts li {
    margin: 0 0 3rem 0;

    h3 {
        margin-bottom: 1rem;
    }
}

a {
    color: $brand-color;
}

.blog-post h2 {
    margin-top: 3rem;
    font-weight: bold;
    text-align: left;
    width: 100%;
}

.post-content p,
.post-content ul {
    text-align: left;
    width: 100%;
}

.blog-post h3 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
}


.post-content img {
    display: block;
    max-width: 560px;
}

.post-content {

    img,
    iframe {
        margin: 0 auto 1.5em;
    }
}

.post-content ul {
    margin-left: 2rem;
    margin-bottom: 20px;
}

.post-content li {
    margin: .5rem 0;
    font-size: 1.38em;
    color: #666;
}


.flex .image {
    @media #{$desktop} {
        min-width: 560px;
    }
}

.image {

    video,
    iframe {
        width: 100%;
        max-width: 560px;
    }
}

.image.flex-list {
    display: flex;
    flex-wrap: wrap;

    >* {
        margin-bottom: 3rem;

        @media #{$tablet} {
            width: 100%;
            flex-grow: 1;
            flex-basis: 0;
        }
    }
}

.banner-alert {
    background-color: #FFE200;
    text-align: center;
    display: block;
    color: #000;
    text-decoration: underline;
}

@media #{$mobile-and-tablet} {
    .tab {
        width: 100% !important;
    }

    .tabcontent {
        width: 100% !important;
        padding: 60px 0px 0px 0px !important;
        text-align: center !important;
        i{
            margin-left: 0 !important;           
        }

    }
}

.tab {
    float: left;
    // border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 30%;
}

.tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #005dff;
    // background-color: #ddd;
    >i{
        color: #fff !important;
      }
      color:#fff;
}

/* Create an active/current "tab button" class */
.tab button.active {
    background-color: #005dff;
  >i{
    color: #fff !important;
  }
  color:#fff;
}

/* Style the tab content */
.tabcontent {
    float: left;
    padding: 60px 0px 0px 40px;
    // border: 1px solid #ccc;
    width: 70%;
    border-left: none;

    i{
        margin-left: 1rem;
    }
}

.tablinks {
    padding: 12px 16px;
    font-size: 20px;
    cursor: pointer;
}

.tab>button>i {
    vertical-align: middle;
    margin-right: 0.5rem;
    color: #666;
}
div.tabcontent>i{
    color: #005dff;
}

.image>img{
    border: 4px solid #ccc;
}


