.sb-subscribe-box {
    background: #005DFF;
    font-family: "Fjalla One";
    padding: 6em 0;
    text-align: center;
    h2 {
        margin-top:0!important;
        color:white;
        text-align: center;
        margin: 0 0 .85em 0;
        font-weight: 100;
        font-size: 30px;
        font-family: "Fjalla One";
    }
    .sb-subscribe {
        width: 100%;
        max-width: 600px;
        margin: auto;
        input:focus{
            box-shadow: none; 
        }
        input {
            width: 100%;
            background: transparent;
            border: 0;
            border-bottom: 1px solid;
            padding: 1em 0 .8em;
            text-align: center;
            font-size: 18px;
            font-family: inherit;
            font-weight: 300;
            line-height: 1.5;
            color: inherit;
            outline: none;
            &::placeholder { 
                color: hsla(0, 0%, 100%, 0.5);
            }
        }

        button {    
            all: unset;
            margin-top: 2.4em;
            background: transparent;
            border: 2px solid white;
            padding: 1em 4em;
            border-radius: 50px;
            cursor: pointer;
            display: inline-block;
            font-weight: 700;
            position: relative;
            transition: all 300ms ease;
            span {
                display: inline-block;
                transition: all 300ms ease;
            }
            &:before, &:after {
                content: "";
                display: block;
                position: absolute;
                transition: all 300ms ease;
                opacity: 0;
            }
            &:before {
                height: 7px;
                width: 7px;
                background: transparent;
                border-right: 2px solid;
                border-top: 2px solid;
                right: 30px; top: 21px;
                transform: rotate(45deg);
            }
            &:after {
                background: white;
                height: 2px;
                width: 50px;
                left: -14px;
                top: 1.54em;
                //z-index: 1;
            }
            &:hover {
                span {
                    transform: translateX(-13px);
                }
                &:before {
                    opacity: 1;
                }
                &:after {
                    width: 14px;
                    opacity: 1;
                    transform: translateX(160px);
                }
            }
        }
    }

}






