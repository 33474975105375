.nav-open nav {
	border-bottom: 1px dotted rgba(255, 255, 255, .2);
	padding: 10px 0;
	a {
		display: block;
	}

	@media #{$mid-point} {
		border: 0;
		padding: 0 20px;

		a {
			display: inline;
		}
	}
}

nav {
	text-transform: uppercase;
	font-size: .8em;
	width: 100%;

	@media #{$mid-point} {
		text-align: right;
		position: absolute;
		top: 13px;
		right: 0;
		padding: 0 20px;
	}


	a {
		margin: 0 3px;
		padding: 20px 10px;
		border-bottom: 1px solid rgba(255,255,255,0);
		color: rgba(255,255,255,.8);
		transition: .2s ease-in-out;
		display: none;

		@media #{$desktop} {
			display: inline;
			padding: 10px;
		}


		&.nav-toggle {
			display: inline;
			position: absolute;
			right: 10px;
			top: -22px;
			font-size: 1.9em;
			border: 0;

			@media #{$desktop} {
				display: none;
			}

			&:hover {
				border: 0;
			}
		}
	}

	a:hover {

		border-bottom: 1px solid rgba(255,255,255,.3);
		color: #fff;
	}

	@media #{$mid-point} {
		a.highlight {
			border: 1px #ccc solid;
			border-radius: 5px;

			&:hover {
				background: #fff;
				color: $brand-color;
			}
		}
	}

	a.active {
		color: #fff;
	}

}

.company__bar {
	display: flex;
}

.company__nav {
	display: flex;
	flex: 1;
    justify-content: flex-end;
}

.company__brand {
	color: #fff;
	padding: .5rem 0rem;

}

.nav__menu {
	display: none;
	position: absolute;
	right: 0;
	z-index: 2;
}

.toggled + .nav__menu {
	display: block;
	width: 250px;
	background-color: #2B2B40;
	padding: 0 1rem 1rem 1rem;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.nav__menu .nav__link {
	border-radius: 5px;
	color: #000;
	background-color: #fff;
	padding: .5rem;
	margin-top: 1rem;
	display: flex;
	align-items: center;
}

.link__icon-wrap {
	padding: 0rem .5rem 0 0;
}

.link__icon {
	max-width: 64px; 
	vertical-align: middle;
}

.link__title {
	font-weight: bold;
	font-size: 1.2rem;
}

.link__description {
	color: #666;
}

.nav__link {
	padding: .5rem 1rem;
	padding: .5rem 0 .5rem 1rem;

	color: #fff;
	display: block;
	cursor: pointer;
}

.nav__caret {
	font-size: .4rem;
	vertical-align: middle;
}

.color-weekplan {
	color: #005dff;
}

.color-remote {
	color: $color-remote;
}

.color-meeting {
	color: $color-meeting;
}

.color-goal {
	color: $color-goal;
}

.color-black {
	color: #000;
}