.bottom-cta {
	background: linear-gradient(to bottom, $brand-color 0%, $middle-gradient-color 100%);
	color: #fff;
	text-align: center;
	margin: 0;
	padding: 100px 0;

	h2 {
		margin-bottom: 50px;
	}
}


.cta-form {
	height: 400px;
}
.testimonial {
	background: #f5f5f5;
	margin: 0;
	padding: 100px 0;

	.testimonial-block {
		max-width: 750px;
		width: 98%;
		margin: 0 auto;

		@media #{$tablet} {
			@include flexbox;

			blockquote {
				-webkit-flex: 1;
				flex: 1;
			}
		}
	}
}

.hero {
	color: #ffffff;
	text-align: center;
	background: linear-gradient(to bottom, $middle-gradient-color 0%, $secondary-brand-color 100%) no-repeat #a05fb7;
	padding-top: 50px;

	p {
		color: #fff;
	}
}


@media #{$desktop} {
	.flex {
		@include flexbox;
		align-items: center;
		flex-direction: row;

		.text{
			-webkit-flex: 1;
			flex: 1;
			padding: 0 20px;
		}
		.image {
			-webkit-flex: 1;
			flex: 1;
			padding: 0;
		}
	}

	.content section:nth-child(even) .flex {
		flex-direction: row-reverse;
	}
}

.landing-page-header{
	background-color:#30b8af;
	h1, p{
		color: white;
	}

	h1 {
		text-align: center;
		padding-top: 40px;
		font-size: 50px; 
	 }
	p { 
		margin-top: 10px;
    	padding-bottom: 30px;
    	text-align: center; 
	}
}
.landing-page-content {
    box-sizing: border-box;
    padding: 1rem;
    vertical-align: top;

    p {
        margin-bottom: 0;
        font-size: 17px;
    }

    h3 {
		font-size: 1.6rem;
		min-height: 0px!important;
	}
	p, h3 {
		text-align: left;
	}
}

.landing-form {
	
	input[type="text"], input[type="email"]{
		border-radius: 30px;
		padding: 20px;
		margin-bottom:10px;
	}

	input[type="submit"]{
		border-radius: 30px;
		height: 60px;
	}
}

.description-container{
	padding-bottom: 50px;
}
