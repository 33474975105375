.feature {
    margin-top: 1rem;
    background-color: $gray;
    position: relative;
    padding: 1rem;

    h4 {
        margin: 0 0 .2rem;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
    }


    p {
        margin-bottom: 0;
    }
}


.feature-icon
{
    width: 90px;
    text-align: center;
    margin-bottom: 1rem;

    @media #{$tablet} {
        margin-bottom: 1rem;
        float: left;
    }
}

.inner-feature {
    overflow: hidden;
    
    @media #{$tablet} {
        margin-left: 90px;
    }
}
