.home-step {
    flex-grow: 1;
    text-align: center;
    position: relative;
}

.home-step-number {
    font-size: 3em;
    color: #aaa;
    margin-bottom: .5rem;
}

.home-step-title {
    font-size: 1.6em;
    margin-bottom: 1em;
}

.home-step-image {
    width: 70%;
}
